import React, { useEffect, useState } from "react";
import "./Header.css";
import { useLocation } from "react-router-dom";

// import { useLocation, useNavigate, } from "react-router-dom";

// import { FaIndianRupeeSign } from "react-icons/fa6";
// import { FaTurkishLiraSign } from "react-icons/fa6";
// import { LuJapaneseYen } from "react-icons/lu";
import { IoStarSharp } from "react-icons/io5";
// import { FaRegUser } from "react-icons/fa";

const Header = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  const handleBookNow = () => {
    window.open(
      "https://www.swiftbook.io/inst/#home?propertyId=423NTEsBm9lPebvuwayhm6SBD3O1MDk=&JDRN=Y"
    );
  };

  // const handleInquire = () => {
  //     navigate('/BookNow');
  // }

  // const handleSignIn = () => {
  //     navigate('/Login');
  // }

  const [header, setHeader] = useState(false);
  const [logoImg, setLogoImg] = useState(false);

  const changeHeaderBg = () => {
    if (window.scrollY >= 400) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeHeaderBg);

  const showLogoImg = () => {
    if (window.scrollY >= 400) {
      setLogoImg(true);
    } else {
      setLogoImg(false);
    }
  };

  window.addEventListener("scroll", showLogoImg);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navbarTop, setNavbarTop] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleChange = () => {
    setIsChanged((prevState) => !prevState);
    setMenuOpen(!menuOpen);
  };
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    location: "",
    downloads: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "downloads") {
      if (value === "Brochure") {
        window.open("../assets/RPJ_Brochure.pdf", "_blank");
      } else if (value === "Kitty Party") {
        window.open("../assets/kitty_party.webp", "_blank");
      } else if (value === "Rooms") {
        window.open("../assets/ROOM_TARIFF.pdf", "_blank");
      } else if (value === "Menu") {
        window.open("../assets/Banquet MENU RPJ.pdf", "_blank");
      } else if (value === "Banquet_Brochure") {
        window.open("../assets/Banquet BROCHURE RPJ.pdf", "_blank");
      }
      setFormData({
        ...formData,
        downloads: "",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (menuOpen) {
        return;
      }
      if (scrollTop > lastScrollTop) {
        setNavbarTop(-150);
      } else {
        setNavbarTop(0);
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, menuOpen]);

  // const [Money_menu, setMoney_menu] = useState(false);

  // const openMoney_menu = () => {
  //     setMoney_menu(true);
  //     // setSignIN_menu(false);
  //     // setLanguage_menu(false);
  // };

  // const closeMoney_menu = () => {
  //     setMoney_menu(false);
  // };

  // const handleArrowClick1 = () => {
  //     const arrowImage = document.getElementById('Arrow1');
  //     const currentRotation = arrowImage.style.transform || 'rotate(0deg)';
  //     const currentRotationValue = parseInt(currentRotation.match(/-?\d+/)[0], 10);

  //     const newRotationValue = currentRotationValue === 0 ? -180 : 0;

  //     arrowImage.style.transition = 'transform 0.5s ease';
  //     arrowImage.style.transform = `rotate(${newRotationValue}deg)`;

  //     setTimeout(() => {
  //         arrowImage.style.transition = '';
  //     }, 300);
  // };

  // const [Language_menu, setLanguage_menu] = useState(false);

  // const openLanguage_menu = () => {
  //     setLanguage_menu(true);
  //     // setSignIN_menu(false);
  //     // setMoney_menu(false);
  // };

  // const closeLanguage_menu = () => {
  //     setLanguage_menu(false);
  // };

  // const handleArrowClick2 = () => {
  //     const arrowImage = document.getElementById('Arrow2');
  //     const currentRotation = arrowImage.style.transform || 'rotate(0deg)';
  //     const currentRotationValue = parseInt(currentRotation.match(/-?\d+/)[0], 10);

  //     const newRotationValue = currentRotationValue === 0 ? -180 : 0;

  //     arrowImage.style.transition = 'transform 0.5s ease';
  //     arrowImage.style.transform = `rotate(${newRotationValue}deg)`;

  //     setTimeout(() => {
  //         arrowImage.style.transition = '';
  //     }, 300);
  // };

  // const [SignIN_menu, setSignIN_menu] = useState(false);

  // const openSignIN_menu = () => {
  //     setSignIN_menu(true);
  //     // setLanguage_menu(false);
  //     // setMoney_menu(false);
  // };

  // const closeSignIN_menu = () => {
  //     setSignIN_menu(false);
  // };

  // const handleArrowClick3 = () => {
  //     const arrowImage = document.getElementById('Arrow3');
  //     const currentRotation = arrowImage.style.transform || 'rotate(0deg)';
  //     const currentRotationValue = parseInt(currentRotation.match(/-?\d+/)[0], 10);

  //     const newRotationValue = currentRotationValue === 0 ? -180 : 0;

  //     arrowImage.style.transition = 'transform 0.5s ease';
  //     arrowImage.style.transform = `rotate(${newRotationValue}deg)`;

  //         setTimeout(() => {
  //             arrowImage.style.transition = '';
  //         }, 300);
  //     };

  return (
    <div>
      {location.pathname === "/" || location.pathname === "/Home" ? (
        <div>
          {/* Start of Mobile screen */}
          <div className="md:hidden bg-white text-black text-[12px] md:text-sm 2xl:text-lg leading-4 text-center flex flex-col items-center justify-center ">
            <div className="flex items-center text-[#b87333]">
              Government Approved&nbsp;
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              &nbsp;Star Hotel
            </div>
            <div className="text-[#154e4e]">Best Corporate Hotel in Town</div>
          </div>
          {/* End of Mobile screen */}

          {/* Start of Large screen */}
          <div className="hidden md:block bg-white text-black text-[12px] md:text-sm 2xl:text-lg leading-4 text-center flex-row items-center justify-center ">
            <div className="flex items-center justify-center font-medium text-[#b87333]">
              Government Approved&nbsp;
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              &nbsp;Star Hotel&nbsp;
              <span className="mx-10 text-black">|</span>&nbsp;
              <span className="text-[#008080]">
                Best Corporate Hotel in Town
              </span>
            </div>
          </div>
          {/* End of Large screen */}

          <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-7 md:leading-8 lg:leading-7 2xl:leading-10">
            {header ? (
              <div
                className="header_active"
                style={{ top: navbarTop, transition: "top 0.5s ease-in-out" }}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div
                      className={isChanged ? "change" : ""}
                      onClick={handleToggleChange}
                    >
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>

                    <a
                      href="/AboutUs"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      About
                    </a>
                    <a
                      href="/Experiences"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Experiences
                    </a>
                    <a
                      href="/Rooms"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="ml-[9vw] lg:-ml-[12vw] 2xl:-ml-[8vw]">
                    <a href="/">
                      <img
                        className={
                          logoImg ? "header_logo" : "header_logo_hidden"
                        }
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>
                  </div>

                  <div className="flex justify-between items-center text-white">
                    {/* <div className='z-50'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openMoney_menu(); handleArrowClick1(); }}>
                                                <FaIndianRupeeSign />
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow1"></img>
                                            </button>
                                            {Money_menu && (
                                                <>
                                                    <div className="overlay_money_menu"
                                                        onClick={() => { closeMoney_menu(); handleArrowClick1(); }}
                                                    />
                                                    <div className="popup_money_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaIndianRupeeSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaTurkishLiraSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <LuJapaneseYen className='font-bold' />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CN<LuJapaneseYen />
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-50'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openLanguage_menu(); handleArrowClick2(); }}>
                                                EN
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow2"></img>
                                            </button>
                                            {Language_menu && (
                                                <>
                                                    <div className="overlay_language_menu"
                                                        onClick={() => { closeLanguage_menu(); handleArrowClick2(); }}
                                                    />
                                                    <div className="popup_language_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    EN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    GUJ
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    HIN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    TR
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    JAP
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CHN
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-50'>
                                            <button className='flex items-center mx-2 lg:mr-5 lg:mx-0' onClick={handleSignIn}>
                                                <FaRegUser />
                                            </button>
                                        </div> */}

                    <button
                      className="inquiry_btn rounded-lg"
                      onClick={handleBookNow}
                    >
                      Book&nbsp;Now
                    </button>
                  </div>
                </div>

                <div
                  className="menu_bg fixed h-[100vh] w-[100vw]"
                  style={{
                    left: menuOpen ? 0 : "-100vw",
                    top: 0,
                    transition: "left 0.5s ease-in-out",
                  }}
                >
                  <div className="Sidebar text-white text-lg md:text-2xl lg:text-xl 2xl:text-2xl flex flex-col mt-16 ml-[3%] lg:ml-[5%]">
                    <a href="/">
                      <img
                        className="header_logo"
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>

                    <a
                      href="/Home"
                      className="mt-[4%] lg:mt-[0%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Home
                    </a>
                    <a
                      href="/BookNow"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Inquire
                    </a>
                    <a
                      href="/AboutUs"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      About Us
                    </a>
                    <a
                      href="/Rooms"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Careers
                    </a>
                    <a
                      href="/Experiences"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Experiences
                    </a>
                    <a
                      href="/Celebrations"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Celebrations
                    </a>
                    <a
                      href="/KitchenStories"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Kitchen Stories
                    </a>
                    <a
                      href="/HotCrusty"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Hot & Crusty
                    </a>
                    <a
                      href="/Gravity"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Gravity
                    </a>
                    <a
                      href="/EventGallery"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Event Gallery
                    </a>

                    <hr className="mt-[2%] mb-[1%] w-[40%] lg:w-[20%]" />

                    <select
                      className="download_dropdown_Header mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                      name="downloads"
                      value={formData.downloads}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled hidden>
                        Download
                      </option>
                      <option
                        value="Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        RPJ Brochure
                      </option>
                      <option
                        value="Rooms"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Room Tariff
                      </option>
                      <option
                        value="Banquet_Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Brochure
                      </option>
                      <option
                        value="Menu"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Menu
                      </option>
                      <option
                        value="Kitty Party"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Kitty Party
                      </option>
                    </select>
                    <a
                      href="/Blogs"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Blogs
                    </a>
                    <span className="mb-[20%] lg:mb-0" />
                    {/* <a href="/" className='mt-[4%] lg:mt-[1.2%] hover:underline' style={{ letterSpacing: '2px' }}>Opening Soon</a> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="header">
                <div className="lg:-ml-[12vw] 2xl:-ml-[8vw]">
                  <a href="/">
                    <img
                      className={logoImg ? "header_logo" : "header_logo_hidden"}
                      src="../assets/rpj_hotel_logo.webp"
                      alt="Rpj-Logo"
                    />
                  </a>
                </div>

                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div
                      className={isChanged ? "change" : ""}
                      onClick={handleToggleChange}
                    >
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>

                    <a
                      href="/AboutUs"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      About
                    </a>
                    <a
                      href="/Experiences"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Experiences
                    </a>
                    <a
                      href="/Rooms"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="ml-[9vw] lg:-ml-[12vw] 2xl:-ml-[8vw]">
                    <a href="/">
                      <img
                        className={
                          logoImg ? "header_logo" : "header_logo_hidden"
                        }
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>
                  </div>

                  <div className="flex items-center justify-between text-white">
                    {/* <div className='z-40'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openMoney_menu(); handleArrowClick1(); }}>
                                                <FaIndianRupeeSign />
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow1"></img>
                                            </button>
                                            {Money_menu && (
                                                <>
                                                    <div className="overlay_money_menu"
                                                        onClick={() => { closeMoney_menu(); handleArrowClick1(); }}
                                                    />
                                                    <div className="popup_money_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaIndianRupeeSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaTurkishLiraSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <LuJapaneseYen className='font-bold' />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CN<LuJapaneseYen />
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-40'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openLanguage_menu(); handleArrowClick2(); }}>
                                                EN
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow2"></img>
                                            </button>
                                            {Language_menu && (
                                                <>
                                                    <div className="overlay_language_menu"
                                                        onClick={() => { closeLanguage_menu(); handleArrowClick2(); }}
                                                    />
                                                    <div className="popup_language_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    EN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    GUJ
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    HIN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    TR
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    JAP
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CHN
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-40'>
                                            <a href='/handleSignIn' target='_blank' className='flex items-center mx-2 lg:mr-5 lg:mx-0'>
                                                <FaRegUser />
                                            </a>
                                        </div> */}

                    <button
                      className="inquiry_btn rounded-lg"
                      onClick={handleBookNow}
                    >
                      Book&nbsp;Now
                    </button>
                  </div>
                </div>

                <div
                  className="menu_bg fixed h-[100vh] w-[100vw]"
                  style={{
                    left: menuOpen ? 0 : "-100vw",
                    top: 0,
                    transition: "left 0.5s ease-in-out",
                  }}
                >
                  <div className="Sidebar text-white text-lg md:text-2xl lg:text-xl 2xl:text-2xl flex flex-col mt-16 ml-[3%] lg:ml-[5%]">
                    <a href="/">
                      <img
                        className="header_logo"
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>

                    <a
                      href="/Home"
                      className="mt-[4%] lg:mt-[0%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Home
                    </a>
                    <a
                      href="/BookNow"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Inquire
                    </a>
                    <a
                      href="/AboutUs"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      About Us
                    </a>
                    <a
                      href="/Rooms"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Careers
                    </a>
                    <a
                      href="/Experiences"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Experiences
                    </a>
                    <a
                      href="/Celebrations"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Celebrations
                    </a>
                    <a
                      href="/KitchenStories"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Kitchen Stories
                    </a>
                    <a
                      href="/HotCrusty"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Hot & Crusty
                    </a>
                    <a
                      href="/Gravity"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Gravity
                    </a>
                    <a
                      href="/EventGallery"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Event Gallery
                    </a>

                    <hr className="mt-[2%] mb-[1%] w-[40%] lg:w-[20%]" />

                    <select
                      className="download_dropdown_Header mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                      name="downloads"
                      value={formData.downloads}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled hidden>
                        Download
                      </option>
                      <option
                        value="Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        RPJ Brochure
                      </option>
                      <option
                        value="Rooms"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Room Tariff
                      </option>
                      <option
                        value="Banquet_Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Brochure
                      </option>
                      <option
                        value="Menu"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Menu
                      </option>
                      <option
                        value="Kitty Party"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Kitty Party
                      </option>
                    </select>
                    <a
                      href="/Blogs"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Blogs
                    </a>
                    <span className="mb-[20%] lg:mb-0" />
                    {/* <a href="/" className='mt-[4%] lg:mt-[1.2%] hover:underline' style={{ letterSpacing: '2px' }}>Opening Soon</a> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {/* Start of Mobile screen */}
          <div className="md:hidden bg-white text-black text-[12px] md:text-sm 2xl:text-lg leading-4 text-center flex flex-col items-center justify-center ">
            <div className="flex items-center text-[#b87333]">
              Government Approved&nbsp;
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              &nbsp;Star Hotel
            </div>
            <div className="text-[#154e4e]">Best Corporate Hotel in Town</div>
          </div>
          {/* End of Mobile screen */}

          {/* Start of Large screen */}
          <div className="hidden md:block bg-white text-black text-[12px] md:text-sm 2xl:text-lg leading-4 text-center flex-row items-center justify-center ">
            <div className="flex items-center justify-center font-medium text-[#b87333]">
              Government Approved&nbsp;
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              <IoStarSharp className="text-[#ffcc00]" />
              &nbsp;Star Hotel&nbsp;
              <span className="mx-10 text-black">|</span>&nbsp;
              <span className="text-[#008080]">
                Best Corporate Hotel in Town
              </span>
            </div>
          </div>
          {/* End of Large screen */}

          <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-7 md:leading-8 lg:leading-7 2xl:leading-10">
            {header ? (
              <div
                className="header_active"
                style={{ top: navbarTop, transition: "top 0.5s ease-in-out" }}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div
                      className={isChanged ? "change" : ""}
                      onClick={handleToggleChange}
                    >
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>

                    <a
                      href="/AboutUs"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      About
                    </a>
                    <a
                      href="/Experiences"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Experiences
                    </a>
                    <a
                      href="/Rooms"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="ml-[9vw] lg:-ml-[12vw] 2xl:-ml-[8vw]">
                    <a href="/">
                      <img
                        className={
                          logoImg ? "header_logo" : "header_logo_hidden"
                        }
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>
                  </div>

                  <div className="flex justify-between items-center text-white">
                    {/* <div className='z-50'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openMoney_menu(); handleArrowClick1(); }}>
                                                <FaIndianRupeeSign />
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow1"></img>
                                            </button>
                                            {Money_menu && (
                                                <>
                                                    <div className="overlay_money_menu"
                                                        onClick={() => { closeMoney_menu(); handleArrowClick1(); }}
                                                    />
                                                    <div className="popup_money_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaIndianRupeeSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaTurkishLiraSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <LuJapaneseYen className='font-bold' />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CN<LuJapaneseYen />
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-50'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openLanguage_menu(); handleArrowClick2(); }}>
                                                EN
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow2"></img>
                                            </button>
                                            {Language_menu && (
                                                <>
                                                    <div className="overlay_language_menu"
                                                        onClick={() => { closeLanguage_menu(); handleArrowClick2(); }}
                                                    />
                                                    <div className="popup_language_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    EN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    GUJ
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    HIN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    TR
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    JAP
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CHN
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-50'>
                                            <button className='flex items-center mx-2 lg:mr-5 lg:mx-0' onClick={handleSignIn}>
                                                <FaRegUser />
                                            </button>
                                        </div> */}

                    <button
                      className="inquiry_btn rounded-lg"
                      onClick={handleBookNow}
                    >
                      Book&nbsp;Now
                    </button>
                  </div>
                </div>

                <div
                  className="menu_bg fixed h-[100vh] w-[100vw]"
                  style={{
                    left: menuOpen ? 0 : "-100vw",
                    top: 0,
                    transition: "left 0.5s ease-in-out",
                  }}
                >
                  <div className="Sidebar text-white text-lg md:text-2xl lg:text-xl 2xl:text-2xl flex flex-col mt-16 ml-[3%] lg:ml-[5%]">
                    <a href="/">
                      <img
                        className="header_logo"
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>

                    <a
                      href="/Home"
                      className="mt-[4%] lg:mt-[0%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Home
                    </a>
                    <a
                      href="/BookNow"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Inquire
                    </a>
                    <a
                      href="/AboutUs"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      About Us
                    </a>
                    <a
                      href="/Rooms"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Careers
                    </a>
                    <a
                      href="/Experiences"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Experiences
                    </a>
                    <a
                      href="/Celebrations"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Celebrations
                    </a>
                    <a
                      href="/KitchenStories"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Kitchen Stories
                    </a>
                    <a
                      href="/HotCrusty"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Hot & Crusty
                    </a>
                    <a
                      href="/Gravity"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Gravity
                    </a>
                    <a
                      href="/EventGallery"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Event Gallery
                    </a>

                    <hr className="mt-[2%] mb-[1%] w-[40%] lg:w-[20%]" />

                    <select
                      className="download_dropdown_Header mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                      name="downloads"
                      value={formData.downloads}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled hidden>
                        Download
                      </option>
                      <option
                        value="Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        RPJ Brochure
                      </option>
                      <option
                        value="Rooms"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Room Tariff
                      </option>
                      <option
                        value="Banquet_Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Brochure
                      </option>
                      <option
                        value="Menu"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Menu
                      </option>
                      <option
                        value="Kitty Party"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Kitty Party
                      </option>
                    </select>
                    <a
                      href="/Blogs"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Blogs
                    </a>
                    <span className="mb-[20%] lg:mb-0" />
                    {/* <a href="/" className='mt-[4%] lg:mt-[1.2%] hover:underline' style={{ letterSpacing: '2px' }}>Opening Soon</a> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="header">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div
                      className={isChanged ? "change" : ""}
                      onClick={handleToggleChange}
                    >
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>

                    <a
                      href="/AboutUs"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      About
                    </a>
                    <a
                      href="/Experiences"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Experiences
                    </a>
                    <a
                      href="/Rooms"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="hidden lg:block text-white hover:underline ml-5"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="ml-[9vw] lg:-ml-[12vw] 2xl:-ml-[8vw]">
                    <a href="/">
                      <img
                        className="header_logo"
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>
                  </div>

                  <div className="flex justify-between items-center text-white">
                    {/* <div className='z-50'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openMoney_menu(); handleArrowClick1(); }}>
                                                <FaIndianRupeeSign />
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow1"></img>
                                            </button>
                                            {Money_menu && (
                                                <>
                                                    <div className="overlay_money_menu"
                                                        onClick={() => { closeMoney_menu(); handleArrowClick1(); }}
                                                    />
                                                    <div className="popup_money_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaIndianRupeeSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <FaTurkishLiraSign />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    <LuJapaneseYen className='font-bold' />
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CN<LuJapaneseYen />
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-50'>
                                            <button className='flex items-center mr-1 lg:mr-5' onClick={() => { openLanguage_menu(); handleArrowClick2(); }}>
                                                EN
                                                <img className="h-5 w-5" src="../assets/white_down_arrow.webp" alt="Down-Arrow" id="Arrow2"></img>
                                            </button>
                                            {Language_menu && (
                                                <>
                                                    <div className="overlay_language_menu"
                                                        onClick={() => { closeLanguage_menu(); handleArrowClick2(); }}
                                                    />
                                                    <div className="popup_language_menu">
                                                        <form>
                                                            <div className='flex flex-col items-center'>
                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    EN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    GUJ
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    HIN
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    TR
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    JAP
                                                                </button>

                                                                <button className='flex items-center justify-center font-bold border border-black border-r-0 border-l-0 w-full py-[10%] lg:py-[8%] my-[8%]'>
                                                                    CHN
                                                                </button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}

                    {/* <div className='z-50'>
                                            <button className='flex items-center mx-2 lg:mr-5 lg:mx-0' onClick={handleSignIn}>
                                                <FaRegUser />
                                            </button>
                                        </div> */}

                    <button
                      className="inquiry_btn rounded-lg"
                      onClick={handleBookNow}
                    >
                      Book&nbsp;Now
                    </button>
                  </div>
                </div>

                <div
                  className="menu_bg fixed h-[100vh] w-[100vw]"
                  style={{
                    left: menuOpen ? 0 : "-100vw",
                    top: 0,
                    transition: "left 0.5s ease-in-out",
                  }}
                >
                  <div className="Sidebar text-white text-lg md:text-2xl lg:text-xl 2xl:text-2xl flex flex-col mt-16 ml-[3%] lg:ml-[5%]">
                    <a href="/">
                      <img
                        className="header_logo"
                        src="../assets/rpj_hotel_logo.webp"
                        alt="Rpj-Logo"
                      />
                    </a>

                    <a
                      href="/Home"
                      className="mt-[4%] lg:mt-[0%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Home
                    </a>
                    <a
                      href="/BookNow"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Inquire
                    </a>
                    <a
                      href="/AboutUs"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      About Us
                    </a>
                    <a
                      href="/Rooms"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Rooms
                    </a>
                    <a
                      href="/Careers"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Careers
                    </a>
                    <a
                      href="/Experiences"
                      className="lg:hidden mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Experiences
                    </a>
                    <a
                      href="/Celebrations"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Celebrations
                    </a>
                    <a
                      href="/KitchenStories"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Kitchen Stories
                    </a>
                    <a
                      href="/HotCrusty"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Hot & Crusty
                    </a>
                    <a
                      href="/Gravity"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Gravity
                    </a>
                    <a
                      href="/EventGallery"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Event Gallery
                    </a>

                    <hr className="mt-[2%] mb-[1%] w-[40%] lg:w-[20%]" />

                    <select
                      className="download_dropdown_Header mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                      name="downloads"
                      value={formData.downloads}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled hidden>
                        Download
                      </option>
                      <option
                        value="Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        RPJ Brochure
                      </option>
                      <option
                        value="Rooms"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Room Tariff
                      </option>
                      <option
                        value="Banquet_Brochure"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Brochure
                      </option>
                      <option
                        value="Menu"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Banquet Menu
                      </option>
                      <option
                        value="Kitty Party"
                        className="options_Orders"
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        Kitty Party
                      </option>
                    </select>
                    <a
                      href="/Blogs"
                      className="mt-[4%] lg:mt-[1.2%] hover:underline"
                      style={{ letterSpacing: "2px" }}
                    >
                      Blogs
                    </a>
                    <span className="mb-[20%] lg:mb-0" />
                    {/* <a href="/" className='mt-[4%] lg:mt-[1.2%] hover:underline' style={{ letterSpacing: '2px' }}>Opening Soon</a> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
