import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const RefundPolicy = () => {
  return (
    <div>
      <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
        <Header />

        <div>
          <img
            className="md:hidden"
            src="../assets/datambanner.webp"
            alt="Header"
          />
          <img
            className="hidden md:block"
            src="../assets/DataWallpaper.webp"
            alt="Header"
          />
          <p
            className="text-[12px] md:text-sm 2xl:text-lg leading-4 text-white text-center font-semibold -mt-20 md:-mt-20 lg:-mt-20 2xl:-mt-28"
            style={{ letterSpacing: "0.5px", wordSpacing: "1px" }}
          >
            SECURITY
          </p>
          <p className="heading text-white text-center lg:text-xl 2xl:text-4xl mt-4">
            Refund Policy
          </p>
        </div>

        <div className="mt-14 md:mt-16 lg:mt-20 2xl:mt-40 px-4 md:px-10 lg:px-14 2xl:px-32">
          <p className="title text-lg md:text-2xl 2xl:text-4xl">
            Refund Policy
          </p>

          <div>
            <p className="font-semibold mt-5">Standard Bookings</p>
            <p>
              •⁠ ⁠Standard: Reservations must be cancelled 72 hours prior to
              arrival to avoid full retention.
            </p>
            <p>
              •⁠ ⁠Non-refundable Rates: Bookings made under non-refundable rates
              are not eligible for refunds.
            </p>
            <p>
              •⁠ ⁠Force Majeure: If you or the Hotel cannot fulfill obligations
              due to events beyond reasonable control (e.g., natural disasters),
              contact the Hotel to discuss potential rebooking or refunds.
            </p>
          </div>

          <div>
            <p className="font-semibold mt-5">Non-Refundable Rates</p>
            <h1>
              •⁠ ⁠Bookings made under specially designated non-refundable rates
              are not eligible for refunds, regardless of the cancellation date.
            </h1>
          </div>

          <div className="font-semibold mt-5"> Force Majeure </div>
          <div>
            <h1>
              •⁠ ⁠In the event of unforeseen circumstances beyond the control of
              either the guest or the hotel (e.g., natural disasters,
              government-imposed travel restrictions), please contact the hotel
              directly to discuss options for rebooking or a potential partial
              refund.
            </h1>
          </div>

          <div>
            <p className="font-semibold mt-5">Important Notes</p>
            <h1>
              •⁠ ⁠This refund policy applies to bookings made directly through
              the RPJ Hotel website. Bookings made through third-party platforms
              may be subject to different cancellation policies.
            </h1>
            <p>
              •⁠ ⁠RPJ HOTEL reserves the right to modify this refund policy at
              any time. Changes will be posted on our website.
            </p>
          </div>
          <br />
        </div>

        {/* Whatsapp icon */}
        <div className="fixed bottom-20 right-3 z-50">
          <a
            href="https://www.instagram.com/rpjhotelrajkot?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/instagramicon.webp"
              alt="Instagram-Icon"
              className="rounded-full hover:scale-110 h-12 w-12 md:h-14 md:w-14 2xl:h-16 2xl:w-16 p-0.5"
            />
          </a>
        </div>

        <div className="fixed bottom-3 right-3 z-50">
          <a
            href="https://api.whatsapp.com/send?phone=+917043877777&text=Hi RPJ Hotel team! I'm planning a trip to Rajkot soon and heard great things about your hotel. Could you please share room availability and rates for date rates? Also, any special offers? Thanks!"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/whatsapp_icon.webp"
              alt="WhatsApp-Icon"
              className="rounded-full hover:scale-110 h-12 w-12 md:h-14 md:w-14 2xl:h-16 2xl:w-16 p-0.5"
            />
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default RefundPolicy;
