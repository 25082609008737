import React, { useState } from "react";
import { PiInstagramLogoLight } from "react-icons/pi";
import { PiFacebookLogoLight } from "react-icons/pi";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { PiPinterestLogoLight } from "react-icons/pi";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    location: "",
  });

  const [touchedEmail, setTouchedEmail] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "downloads") {
      if (value === "Brochure") {
        window.open("../assets/RPJ_Brochure.pdf", "_blank");
      } else if (value === "Kitty Party") {
        window.open("../assets/kitty_party.webp", "_blank");
      } else if (value === "Rooms") {
        window.open("../assets/Room_RPJ.pdf", "_blank");
      } else if (value === "Packages") {
        window.open("../assets/PACKAGES_RPJ.pdf", "_blank");
      }
      setFormData({
        ...formData,
        downloads: "",
      });
    }

    if (name === "email") {
      setTouchedEmail(true); // Mark email input as touched when user interacts with it
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const [missingFields, setMissingFields] = useState([]);

  const handleEmail = async () => {
    const requiredFields = ["name", "phone", "email", "location"];
    const newMissingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newMissingFields.push(field);
      }
    });

    if (!isValidEmail(formData.email)) {
      newMissingFields.push("invalidEmail");
    }

    if (newMissingFields.length > 0) {
      setMissingFields(newMissingFields);
      return;
    }

    if (missingFields.length === 0) {
      try {
        const response = await fetch(
          "https://inittechnology.co/subscribeForRpj",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.status === 200) {
          alert(
            `You're officially subscribed! Enjoy being a part of our VIP circle.`
          );
          setFormData({ email: "" });
        } else if (response.status === 409) {
          alert("Email already exists");
        } else {
          alert("Error inserting email");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className="bg-[#232323] text-white py-10">
        <div className="px-6 md:px-16 lg:px-36 2xl:px-64">
          <div className="md:hidden ">
            <div className="grid grid-cols-2 lg:grid-cols-4">
              <div className="w-24 md:w-36 2xl:w-44 ">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-2">
                  SOCIAL
                </p>
                <div className="flex flex-col">
                  <a
                    href="https://bit.ly/43WMKBO"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiInstagramLogoLight />
                    &nbsp; Instagram
                  </a>
                  <a
                    href="https://bit.ly/43WMGC4"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiFacebookLogoLight />
                    &nbsp; Facebook
                  </a>
                  <a
                    href="https://bit.ly/4aTWoaA"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiYoutubeLogoLight />
                    &nbsp; YouTube
                  </a>
                  <a
                    href="https://bit.ly/43WJAhn"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiPinterestLogoLight />
                    &nbsp; Pinterest
                  </a>
                </div>
              </div>

              <div className="w-32 -ml-3 md:-ml-12">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-3">
                  POLICY
                </p>
                <div className="flex flex-col">
                  <a
                    href="/DataPrivacy"
                    className="leading-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                  >
                    Data Privacy and Security Privacy
                  </a>
                  <a
                    href="/TermsConditions"
                    className="mt-2 md:-mt-4 leading-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                  >
                    Terms & Conditions
                  </a>
                  <a
                    href="/RefundPolicy"
                    className="mt-2 md:mt-4 leading-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                  >
                    Refund Policy
                  </a>
                  <select
                    className="download_dropdown mt-2 md:mt-4 leading-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                    name="downloads"
                    value={formData.downloads}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled hidden>
                      Downloads
                    </option>
                    <option value="Brochure" className="options_Orders">
                      RPJ Brochure
                    </option>
                    <option value="Rooms" className="options_Orders">
                      Room Tariff
                    </option>
                    <option value="Packages" className="options_Orders">
                      Banquet Packages
                    </option>
                    <option value="Kitty Party" className="options_Orders">
                      Kitty Party
                    </option>
                  </select>
                  <br />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1">
              <div className="md:w-72 2xl:w-96 mt-5 md:-ml-16">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-2">
                  CONTACT
                </p>
                <div className="flex flex-col">
                  <a
                    href="https://maps.app.goo.gl/RLd1mpkH64atnZCy6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="leading-5 md:leading-6 2xl:leading-8 hover:text-[#008080]"
                  >
                    Operational Address: A unit of PP Developers; Kalavad Rd,
                    Near Everest Park, Jai Bhimnagar, Nana Mava, Rajkot, Gujarat
                    360005
                  </a>

                  {/* <a href='https://maps.app.goo.gl/RLd1mpkH64atnZCy6' target='_blank' rel='noopener noreferrer'
                                        className='leading-5 md:leading-6 2xl:leading-8 hover:text-[#008080]'>
                                        Everest Park, Kalawad Road, near Jaddu's Food Field and McDonald's, Rajkot, India 360005
                                    </a> */}

                  <a href="/" className="my-1 md:my-4">
                    <div className="flex hover:text-[#008080]">
                      <a href="tel:+912816167777" className="flex items-center">
                        <BsTelephone />
                        &nbsp; 0281 616 7777
                      </a>
                    </div>
                  </a>

                  <a href="/" className="hover:text-[#008080]">
                    <div className="flex">
                      <a
                        href="mailto:reservations@rpjhotels.com"
                        className="flex items-center"
                      >
                        <AiOutlineMail />
                        &nbsp; reservations@rpjhotels.com
                      </a>
                    </div>
                  </a>

                  {/* <a href="/Careers" className="mt-5 hover:text-[#008080]">
                    Careers
                  </a> */}
                </div>
              </div>

              <div className="w-[88vw] lg:w-[22vw] mt-6 md:mt-8 lg:mt-0">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-2">
                  SUBSCRIBE TO OUR NEWSLETTER
                </p>
                <div className="flex flex-col">
                  <p className="leading-4 md:leading-6 2xl:leading-7">
                    Subscribe our newsletter to receive the latest news and
                    exclusive offers.
                  </p>

                  <div className="flex flex-col mt-3 text-sm md:text-lg lg:text-sm 2xl:text-2xl">
                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {missingFields.includes("name") && (
                      <p className="text-red-500">Please enter your name</p>
                    )}
                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Contact No."
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {missingFields.includes("phone") && (
                      <p className="text-red-500">
                        Please enter your phone number
                      </p>
                    )}
                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {touchedEmail && !isValidEmail(formData.email) && (
                      <p className="text-red-500">
                        Please enter a valid email address
                      </p>
                    )}
                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Location"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    />
                    {missingFields.includes("location") && (
                      <p className="text-red-500">Please enter your location</p>
                    )}
                  </div>

                  <div className="flex mt-5 text-sm md:text-lg lg:text-sm 2xl:text-2xl">
                    <button
                      className="button_BookNow text-sm"
                      style={{ "--clr": "#7808d0" }}
                      onClick={handleEmail}
                    >
                      Subscribe
                      <span className="button__icon-wrapper_BookNow">
                        <svg
                          width="10"
                          className="button__icon-svg_BookNow"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 15"
                        >
                          <path
                            fill="currentColor"
                            d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                          ></path>
                        </svg>

                        <svg
                          className="button__icon-svg_BookNow  button__icon-svg--copy_BookNow"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          fill="none"
                          viewBox="0 0 14 15"
                        >
                          <path
                            fill="currentColor"
                            d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden md:block">
            <div className="grid grid-cols-3 lg:grid-cols-4">
              <div className=" w-24 md:w-36 2xl:w-44 ">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-2">
                  SOCIAL
                </p>
                <div className="flex flex-col">
                  <a
                    href="https://bit.ly/43WMKBO"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiInstagramLogoLight />
                    &nbsp; Instagram
                  </a>
                  <a
                    href="https://bit.ly/43WMGC4"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiFacebookLogoLight />
                    &nbsp; Facebook
                  </a>
                  <a
                    href="https://bit.ly/4aTWoaA"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiYoutubeLogoLight />
                    &nbsp; YouTube
                  </a>
                  <a
                    href="https://bit.ly/43WJAhn"
                    target="_new"
                    className="flex items-center mb-1 hover:scale-105 hover:text-[#008080]"
                  >
                    <PiPinterestLogoLight />
                    &nbsp; Pinterest
                  </a>
                </div>
              </div>

              <div className="w-24 md:w-40 2xl:w-56 -ml-3 md:-ml-12">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-3">
                  POLICY
                </p>
                <div className="flex flex-col">
                  <a
                    href="/DataPrivacy"
                    className="md:leading-6 2xl:leading-7 hover:text-[#008080]"
                  >
                    Data Privacy and Security Privacy
                  </a>
                  <a
                    href="/TermsConditions"
                    className="md:mt-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                  >
                    Terms & Conditions
                  </a>
                  <a
                    href="/RefundPolicy"
                    className="md:mt-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                  >
                    Refund Policy
                  </a>
                  <select
                    className="download_dropdown mt-2 md:mt-4 leading-4 md:leading-6 2xl:leading-7 hover:text-[#008080]"
                    name="downloads"
                    value={formData.downloads}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled hidden>
                      Downloads
                    </option>
                    <option value="Brochure" className="options_Orders">
                      RPJ Brochure
                    </option>
                    <option value="Rooms" className="options_Orders">
                      Room Tariff
                    </option>
                    <option value="Packages" className="options_Orders">
                      Banquet Packages
                    </option>
                    <option value="Kitty Party" className="options_Orders">
                      Kitty Party
                    </option>
                  </select>
                  <br />
                </div>
              </div>

              <div className="w-40 md:w-[98%] 2xl:w-96 -ml-7 md:-ml-16">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-2">
                  CONTACT
                </p>
                <div className="flex flex-col">
                  <a
                    href="https://maps.app.goo.gl/RLd1mpkH64atnZCy6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm md:text-lg lg:text-sm 2xl:text-2xl hover:text-[#008080]"
                  >
                    Operational Address: A unit of PP Developers; Kalavad Rd,
                    Near Everest Park, Jai Bhimnagar, Nana Mava, Rajkot, Gujarat
                    360005
                  </a>

                  {/* <a href='https://maps.app.goo.gl/RLd1mpkH64atnZCy6' target='_blank' rel='noopener noreferrer'
                                        className='leading-5 md:leading-6 2xl:leading-8 hover:text-[#008080]'>
                                        Everest Park, Kalawad Road, near Jaddu's Food Field and McDonald's, Rajkot, India 360005
                                    </a> */}

                  <a href="/" className="my-1 md:my-4">
                    <div className="flex hover:text-[#008080]">
                      <a href="tel:+912816167777" className="flex items-center">
                        <BsTelephone />
                        &nbsp; 0281 616 7777
                      </a>
                    </div>
                  </a>

                  <a href="/" className="hover:text-[#008080]">
                    <div className="flex">
                      <a
                        href="mailto:reservation@rpjhotels.com"
                        className="flex items-center"
                      >
                        <AiOutlineMail />
                        &nbsp; reservation@rpjhotels.com
                      </a>
                    </div>
                  </a>

                  <a href="/Careers" className="mt-5 hover:text-[#008080]">
                    Careers
                  </a>
                </div>
              </div>

              <div className="w-[88vw] lg:w-[22vw] mt-6 md:mt-8 lg:mt-0">
                <p className="title text-sm md:text-lg lg:text-sm 2xl:text-2xl mb-2">
                  SUBSCRIBE TO OUR NEWSLETTER
                </p>
                <div className="flex flex-col">
                  <p className="leading-4 md:leading-6 2xl:leading-7">
                    Subscribe to our newsletter to receive the latest news and
                    exclusive offers.
                  </p>

                  <div className="flex flex-col mt-3 text-sm md:text-lg lg:text-sm 2xl:text-2xl">
                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {missingFields.includes("name") && (
                      <p className="text-red-500">Please enter your name</p>
                    )}

                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Contact No."
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {missingFields.includes("phone") && (
                      <p className="text-red-500">
                        Please enter your phone number
                      </p>
                    )}

                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {touchedEmail && !isValidEmail(formData.email) && (
                      <p className="text-red-500">
                        Please enter a valid email address
                      </p>
                    )}

                    <input
                      className="rounded bg-transparent outline-[#008080] py-0.5 px-3 border-[1px] my-2 w-60 md:w-80 lg:w-56 2xl:w-96"
                      type="text"
                      placeholder="Location"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    />
                    {missingFields.includes("location") && (
                      <p className="text-red-500">Please enter your location</p>
                    )}
                  </div>

                  <div className="flex mt-5 text-sm md:text-lg lg:text-sm 2xl:text-2xl">
                    <button
                      className="button_BookNow text-sm !text-white"
                      style={{ "--clr": "#7808d0" }}
                      onClick={handleEmail}
                    >
                      Subscribe
                      <span className="button__icon-wrapper_BookNow">
                        <svg
                          width="10"
                          className="button__icon-svg_BookNow !text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 15"
                        >
                          <path
                            fill="currentColor"
                            d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                          ></path>
                        </svg>

                        <svg
                          className="button__icon-svg_BookNow  button__icon-svg--copy_BookNow"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          fill="none"
                          viewBox="0 0 14 15"
                        >
                          <path
                            fill="currentColor"
                            d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-0.5 mx-6 md:mx-10 mt-12 mb-2"></div>

        <div className="mx-8 md:mx-12 font-light">
          <a
            href="https://www.inittechnology.co/"
            className="text-[12px] md:text-lg lg:text-sm 2xl:text-2xl"
            target="blank"
          >
            Copyright © Init Technology 2025
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
